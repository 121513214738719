<template>
  <div class="footer">
    <p>Developed by SEVEN ©SEVENZLS 2017-2020 滇ICP备17010711号-1</p>
  </div>
</template>
<script>
export default {
  name: 'PageFooter'
}
</script>
<style lang="stylus" scoped>
.footer
  background-color: #000000
  color: #FFFFFF
  margin-top 20px
  height 120px
  text-align center
  width 100%
  line-height: 100px
  @media screen and (max-width: 1000px)
    line-height: 100%
  font-size: 20px
</style>
